import React from 'react';

const BaseIcon = (
  Path: React.ComponentType<{ fill: string }>,
  viewBox: string
): React.FunctionComponent<{ fill: string; size: number | string }> => {
  return ({ fill, size }) => (
    <svg width={size} height={size} viewBox={viewBox}>
      <Path fill={fill} />
    </svg>
  );
};

export default BaseIcon;
