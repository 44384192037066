import React from 'react';
import styled, { keyframes } from 'styled-components';
import colors from '../../constants/colors';

const Animation = keyframes`
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
`;

const DoubleBounce = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${colors.AQUA75};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  animation: ${Animation} 2s infinite ease-in-out;
`;

const DoubleBounce2 = styled(DoubleBounce)`
  animation-delay: -1s;
  background-color: ${colors.AQUA25};
`;

const IndicatorContainer = styled.div`
  height: 32px;
  position: relative;
  width: 32px;
`;

const LoadingIndicator = () => (
  <IndicatorContainer>
    <DoubleBounce />
    <DoubleBounce2 />
  </IndicatorContainer>
);

export default LoadingIndicator;
