import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import styled, { createGlobalStyle } from 'styled-components';
import * as Sentry from '@sentry/browser';
import Button from '../Button';
import ErrorNotification from '../ErrorNotification';
import LoadingIndicator from '../LoadingIndicator';
import colors from '../../constants/colors';
import resizePhoto from '../../functions/resizePhoto';
import idBack from '../../resources/id-back.png';
import idFront from '../../resources/id-front.png';
import logo from '../../resources/logo.png';
import selfie from '../../resources/selfie.png';

const { NODE_ENV, REACT_APP_API_HOST: API_HOST } = process.env;

const AppGlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    background-color: white;
    color: ${colors.PRIMARY_TEXT};
    font-family: 'Lato', sans-serif;
    height: 100vh;
    margin: 0;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
      outline: none;
  }
`;

const Border = styled.div`
  background-color: ${colors.BORDER};
  height: 1px;
  width: 100%;
`;

const CapturePhotoContent = styled.div`
  padding-block-end: 2em;
`;

const Logo = styled.img`
  height: 100%;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 3.5em;
  justify-content: center;
  padding: 0.7em;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 5em;
  padding: 0.6em;
`;

const TitleText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 0.2em;
`;

const TitleSubText = styled.div`
  color: ${colors.SECONDARY_TEXT};
  text-align: center;
`;

const MainContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2em;
  padding: 0.6em 1em;

  & input {
    display: none;
  }
`;

const FileUploadLabel = styled.label`
  cursor: pointer;
`;

const PhotoContainer = styled.div<{ photo: boolean }>`
  border: 2px solid ${({ photo }) => (photo ? colors.AQUA : colors.BORDER)};
  border-radius: 0.5em;
  padding: 1em;
`;

const PhotoIconContainer = styled.div`
  height: 8em;
  line-height: 8em;
  text-align: center;
  width: 8em;
`;

const PhotoIcon = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
`;

const PhotoName = styled.div`
  padding-bottom: 0.5em;
  text-align: center;
`;

const InstructionsContainer = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-weight: bold;
  margin-bottom: 1.5em;
  text-align: center;
`;

const LoadingIndicatorContainer = styled.div`
  margin-bottom: 0.5em;
`;

const LoadingStateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 20em;
  justify-content: center;
  text-align: center;
`;

const VerificationCompleted = styled(LoadingStateContainer)`
  font-weight: bold;
`;

interface Geolocation {
  latitude: number;
  longitude: number;
}

interface Photos {
  [key: string]: File;
}

type PhotoTypes = 'id-front' | 'id-back' | 'selfie';

interface PhotoConfig {
  icon: string;
  name: string;
  type: PhotoTypes;
}

const idConfig: Array<PhotoConfig> = [
  {
    icon: idFront,
    name: 'INE - Frente',
    type: 'id-front',
  },
  {
    icon: idBack,
    name: 'INE - Reverso',
    type: 'id-back',
  },
  {
    icon: selfie,
    name: 'Selfie',
    type: 'selfie',
  },
];

const CapturePhoto: React.FunctionComponent<{
  icon: string;
  id: string;
  name: string;
  onChange(photo: File): void;
  photo?: File;
}> = ({ icon, id, name, onChange, photo }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target !== null &&
      event.target.files !== null &&
      event.target.files.length
    ) {
      const file = event.target.files[0];
      resizePhoto(file, resizedPhoto => onChange(resizedPhoto));
    }
  };

  return (
    <CapturePhotoContent>
      <PhotoName>{name}</PhotoName>
      <input
        id={id}
        type="file"
        accept="image/*;capture=camera"
        onChange={handleInputChange}
      />
      <FileUploadLabel htmlFor={id}>
        <PhotoContainer photo={photo !== undefined}>
          <PhotoIconContainer>
            <PhotoIcon src={icon} />
          </PhotoIconContainer>
        </PhotoContainer>
      </FileUploadLabel>
    </CapturePhotoContent>
  );
};

const LoadingState: React.FunctionComponent = () => {
  return (
    <LoadingStateContainer>
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
      Por favor espera mientras verificamos tu identidad
    </LoadingStateContainer>
  );
};

const getTitleText = (loading: boolean, verificationCommpleted: boolean) => {
  if (loading) {
    return 'Verificación de identidad';
  } else if (verificationCommpleted) {
    return 'Verificación terminada';
  } else {
    return 'Comienza tu verificación';
  }
};

const getSubtitleText = (loading: boolean, verificationCommpleted: boolean) => {
  if (loading) {
    return 'Este proceso puede tardar varios minutos';
  } else if (verificationCommpleted) {
    return 'Continúa con el proceso de creación de cuenta';
  } else {
    return 'El proceso está diseñado para proteger tu identidad';
  }
};

const App: React.FunctionComponent<{}> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [photos, setPhotos] = useState<Photos>({});
  const [error, setError] = useState<string | null>(null);
  const [geolocation, setGeolocation] = useState<Geolocation | null>(() => {
    if (process.env.NODE_ENV === 'development') {
      return { latitude: 20.720896, longitude: -103.41519 };
    }

    return null;
  });
  const [verificationCommpleted, setVerificationCompleted] = useState<boolean>(
    false
  );

  const onPhotoChange = (photo: File, index: string) => {
    const newPhotos = { ...photos };
    newPhotos[index] = photo;

    setPhotos(newPhotos);
  };

  const removePhoto = (index: string) => {
    const newPhotos = { ...photos };
    delete newPhotos[index];

    setPhotos(newPhotos);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => setGeolocation(position.coords),
        () => {},
        { timeout: 30000 }
      );
    }
  }, []);

  const biometricVerificationId = new URLSearchParams(
    window.location.search
  ).get('id');

  useEffect(() => {
    Sentry.setExtra('verificationId', biometricVerificationId);
  }, [biometricVerificationId]);

  const allPhotosTaken =
    Object.keys(photos).filter(photo => photos[photo] !== undefined).length ===
    Object.keys(idConfig).length;

  const onSendButtonClicked = () => {
    if (biometricVerificationId !== null && allPhotosTaken) {
      const payload = new FormData();

      idConfig.forEach(photoConfig => {
        payload.append('photos[]', photos[photoConfig.type]);
        payload.append('photo_types[]', photoConfig.type);
      });

      if (geolocation !== null) {
        payload.append('lat', `${geolocation.latitude}`);
        payload.append('lng', `${geolocation.longitude}`);
      }

      setLoading(true);

      axios
        .post(
          `${API_HOST}/api/biometric-verification/${biometricVerificationId}`,
          payload
        )
        .then(() => {
          setLoading(false);
          setVerificationCompleted(true);
        })
        .catch(e => {
          if (NODE_ENV === 'production') {
            Sentry.captureException(e);
          }
          setLoading(false);
          switch (e.response.data.error) {
            case 'FRONT_ID_PHOTO_IS_BW':
              removePhoto(idConfig[0].type);
              setError(
                'Se detectó que el frente de la identificación está en blanco y negro. Por favor, utiliza fotografías o imágenes escaneadas a color.'
              );
              break;
            case 'BACK_ID_PHOTO_IS_BW':
              removePhoto(idConfig[1].type);
              setError(
                'Se detectó que el reverso de la identificación está en blanco y negro. Por favor, utiliza fotografías o imágenes escaneadas a color.'
              );
              break;
            case 'SELFIE_IS_BW':
              removePhoto(idConfig[2].type);
              setError(
                'Se detectó que la selfie está en blanco y negro. Por favor, utiliza una fotografía a color.           '
              );
              break;
            case 'FRONT_ID_PHOTO_CONTAINS_NO_ID':
              removePhoto(idConfig[0].type);
              setError(
                'No se detectó el frente de la identificación. Por favor, realiza una nueva captura. El frente de la identificación debe verse completa y claramente.'
              );
              break;
            case 'SELFIE_CONTAINS_NO_VALID_FACE':
              removePhoto(idConfig[2].type);
              setError(
                'No se detectó una cara de una persona en la selfie. Por favor, realiza una nueva captura. La cara del sujeto debe debe verse claramente y en el primer plano de la imágen. '
              );
              break;
            case 'SELFIE_CONTAINS_ID':
              removePhoto(idConfig[2].type);
              setError(
                'Se detectó una identificación en la selfie. Por favor, realiza una nueva captura donde no se vea una identificación. La cara del sujeto debe debe verse claramente y en el primer plano de la imágen.'
              );
              break;
            case 'BACK_ID_PHOTO_IS_INVALID':
              removePhoto(idConfig[1].type);
              setError(
                'No se detectó el reverso de la identificación. Por favor, realiza una nueva captura. El reverso de la identificación debe verse completa y claramente.'
              );
              break;
            case 'SELFIE_AND_ID_TOO_SIMILAR':
              removePhoto(idConfig[2].type);
              setError(
                'Se detectó la misma imágen en la selfie y el frente de la identificación. Por favor, no utilice una identificación como selfie.'
              );
              break;
            case 'SELFIE_AND_ID_VERY_DIFFERENT':
              removePhoto(idConfig[0].type);
              removePhoto(idConfig[1].type);
              removePhoto(idConfig[2].type);
              setError(
                'Se detectó que el sujeto de la selfie no es el mismo que el del frente de la identificación. Las imágenes tienen que ser de la misma persona.'
              );
              break;
            case 'DIFFERENT_SELFIE_TO_PREVIOUS_APPLICATION':
              removePhoto(idConfig[0].type);
              removePhoto(idConfig[1].type);
              removePhoto(idConfig[2].type);
              setError(
                'Se detectó que el sujeto de la selfie no es el mismo que el de verificaciones previas. Las imágenes tienen que ser de la misma persona.'
              );
              break;
            case 'BIOMETRIC_VERIFICATION_NOT_FOUND':
              removePhoto(idConfig[0].type);
              removePhoto(idConfig[1].type);
              removePhoto(idConfig[2].type);
              setError(
                'La liga de la verificación que está tratando de utilizar es inválida o no existe. Por favor realiza una nueva verificación y sigue el nuevo enlace.'
              );
              break;
            case 'BIOMETRIC_VERIFICATION_ALREADY_USED':
              removePhoto(idConfig[0].type);
              removePhoto(idConfig[1].type);
              removePhoto(idConfig[2].type);
              setError(
                'La liga de la verificación que está tratando de utilizar ya fue usada. Por favor realiza una nueva verificación y sigue el nuevo enlace.'
              );
              break;
            case 'COULD_NOT_READ_FILES_BUFFER':
            default:
              setError(
                'Ha ocurrido un error. Por favor contacta a soporte al 33-1803-7895.'
              );
              break;
          }
          setTimeout(function() {
            setError(null);
          }, 10000);
        });
    }
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingState />;
    } else if (verificationCommpleted) {
      return (
        <VerificationCompleted>
          Ha concluido el proceso de verificación
        </VerificationCompleted>
      );
    } else {
      return (
        <Fragment>
          <InstructionsContainer>
            Captura las siguientes fotografías siguiendo las imágenes como guía
          </InstructionsContainer>
          {idConfig.map(photoConfig => (
            <CapturePhoto
              key={photoConfig.type}
              icon={photoConfig.icon}
              id={photoConfig.type}
              name={photoConfig.name}
              onChange={photo => onPhotoChange(photo, photoConfig.type)}
              photo={photos[photoConfig.type]}
            />
          ))}
          <Button
            onClick={onSendButtonClicked}
            variant={allPhotosTaken ? 'primary' : 'inactive'}
          >
            Terminar verificación
          </Button>
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <AppGlobalStyle />
      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>
      <Border />
      {error ? (
        <ErrorNotification error={error} onClick={() => setError(null)} />
      ) : null}
      <Title>
        <TitleText>{getTitleText(loading, verificationCommpleted)}</TitleText>
        <TitleSubText>
          {getSubtitleText(loading, verificationCommpleted)}
        </TitleSubText>
      </Title>
      <Border />
      <MainContent>{renderContent()}</MainContent>
    </Fragment>
  );
};

export default App;
