import React from 'react';
import BaseIcon from './BaseIcon';
import colors from '../../constants/colors';

const viewBox = '0 0 20 20';

const ImportantIcon = ({ fill }: { fill: string }): JSX.Element => (
  <path
    d="M11 5.007v7a1 1 0 0 1-2 0v-7a1 1 0 0 1 2 0zm0 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM18 17a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14zm0-17H2C.895 0 0 .899 0 2.003v16.004C0 19.112.895 20 2 20h16c1.105 0 2-.892 2-1.997V2.007C20 .902 19 0 18 0z"
    fill={fill || colors.DUSK}
  />
);

export default BaseIcon(ImportantIcon, viewBox);
