import React from 'react';
import styled from 'styled-components';
import ImportantIcon from '../../resources/icons/ImportantIcon';
import colors from '../../constants/colors';

const ErrorContainer = styled.div`
  left: 5vw;
  position: fixed;
  z-index: 1000;
`;

const Error = styled.div`
  align-items: center;
  background-color: ${colors.RED};
  border-radius: 4px;
  color: ${colors.WHITE};
  display: flex;
  margin-bottom: 20px;
  min-height: 52px;
  padding: 16px;
  width: 90vw;
`;

const ErrorIconContainer = styled.div`
  margin-right: 12px;
`;

const ErrorNotification: React.FunctionComponent<{
  onClick: () => void;
  error: string;
}> = ({ onClick, error }) => {
  return (
    <ErrorContainer>
      <Error onClick={onClick}>
        <ErrorIconContainer>
          <ImportantIcon fill={colors.WHITE} size={'1em'} />
        </ErrorIconContainer>
        {error}
      </Error>
    </ErrorContainer>
  );
};

export default ErrorNotification;
